<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>To install on iPad you can use Flex IPTV or GSE SMART IPTV</p>
								<p><u>*To remove GSE watermark you need to buy the full version</u>&nbsp;</p>
								<p><br /><br /><strong>Flex IPTV</strong><br />Search FLEX IPTV in the App Store or download from&nbsp;</p>
								<p>
									<a href="https://apps.apple.com/ca/app/flex-iptv/id1182930255" rel="noreferrer noopener" target="_top"
										>https://apps.apple.com/ca/app/flex-iptv/id1182930255</a
									><br /><br />Once installed click settings<br />Add playlist Remote<br /><br />
								</p>
								<p>Insert the required m3u and replace <strong>YOUR_</strong><strong>EMAIL/ PASSWORD</strong> with your details<br /><br /></p>
								<p>
									<strong>Channels list and recent VOD</strong><br /><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong>/</strong>m3u8/livetv</a
									>
								</p>
								<p>
									<br /><strong>Movies&nbsp;</strong><br /><a
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										rel="noreferrer noopener"
										target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/movies</a
									><br /><br /><strong>TV Shows</strong><br /><a
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"
										rel="noreferrer noopener"
										target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/tvshows</a
									>
								</p>
								<p><br /></p>
								<p>After click ADD EPG and insert your required EPG (only one)</p>
								<p><br /></p>
								<p><strong>TV Guide/EPG URL</strong><br />Select Lite or Full url.&nbsp;</p>
								<p><br /></p>
								<p>Regular version – 7 day listing&nbsp;</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`" rel="noreferrer noopener" target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/full.xml.gz</a
									>
								</p>
								<p><br /></p>
								<p>Lite version - 48h listing (For old/small devices)</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`" rel="noreferrer noopener" target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/lite.xml.gz</a
									>
								</p>
								<p>&nbsp;</p>
								<p><br /></p>
								<p><strong>GSE SMART IPTV</strong></p>
								<p>
									Download the free GSE SMART IPTV App from The App Store.<br />(Please note the free version has advertisements but you can unlock the full
									version for a small fee if you donʼt like advertisements)<br /><br />Once your app is opened you will see 3 stripes on the top left corner of
									the screen.<br /><br />Click the 3 lines to open the main menu.<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE2.png`"
										rel="noreferrer noopener"
										target="_top"
										><img
											src="../../../assets/images/eNaz9LVcpCix5JjXsXNJXqLhCIU9v4mODw.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/eNaz9LVcpCix5JjXsXNJXqLhCIU9v4mODw.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627306"
											data-uniquekey="1567896897327" /></a
									><br /><br />
								</p>
								<p>
									Select the “Remote Playlists” tab on the left side of the screen.<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE3.png`"
										rel="noreferrer noopener"
										target="_top"
										><img
											src="../../../assets/images/04mfveRtEhGoF6ggS17_ya-4B0Cqpe-5Mw.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/04mfveRtEhGoF6ggS17_ya-4B0Cqpe-5Mw.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627309"
											data-uniquekey="1567896897327" /></a
									><br /><br />
								</p>
								<p>
									Click the “+” plus button in the top right corner of the app.<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE4.png`"
										rel="noreferrer noopener"
										target="_top"
										><img
											src="../../../assets/images/ccxBQpWzUsKn3Jhmb2dftVDFMSBw7AiChg.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/ccxBQpWzUsKn3Jhmb2dftVDFMSBw7AiChg.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627305"
											data-uniquekey="1567896897327" /></a
									><br /><br />
								</p>
								<p>
									Select “Add M3U URL” from the pop up screen.<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE5.png`"
										rel="noreferrer noopener"
										target="_top"
										><img
											src="../../../assets/images/ug-ipox1ixleJuJGcUhHJ1Aj1yi7ictysw.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/ug-ipox1ixleJuJGcUhHJ1Aj1yi7ictysw.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627307"
											data-uniquekey="1567896897327" /></a
									><br /><br />
								</p>
								<p>
									A new tab will open up.<br />Click the top line (Playlist name) and type in the words “{{ $store.state.brandInfo.displayName }} ”. (No quotation
									marks)<br />On the second line enter:<br /><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong>/</strong>m3u8/livetv</a
									><strong><br /></strong>*Replace the bold text with your account info – <strong>YOUR_EMAIL&nbsp;</strong>and <strong>PASSWORD</strong
									><br />**Note: The password is Case-Sensitive<br />***Double check that you typed out the link exactly.<br /><br />
								</p>
								<p>
									After ensuring your M3U playlist was typed in correctly, select the red “Add” button.<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE6.png`"
										rel="noreferrer noopener"
										target="_top"
										><img
											src="../../../assets/images/c_Yl-Uj8jKQbygekmUH4IEpezi9vSrJ9Cw.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/c_Yl-Uj8jKQbygekmUH4IEpezi9vSrJ9Cw.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627304"
											data-uniquekey="1567896897327" /></a
									><br /><br />
								</p>
								<p>Select the newly created “{{ $store.state.brandInfo.displayName }} ” tab.</p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/GSE7.png`" rel="noreferrer noopener" target="_top"
										><img
											src="../../../assets/images/VlzSm1IPK167vHkmC_q2ylQvhcWEadMiDg.png?1567896943"
											alt=""
											data-filelink="../../../assets/images/VlzSm1IPK167vHkmC_q2ylQvhcWEadMiDg.png?1567896943"
											class="fr-fic fr-dii"
											data-fileid="48006627308"
											data-uniquekey="1567896897327"
									/></a>
								</p>
								<p><br /></p>
								<p>
									*If you followed all the steps correctly you will now see that {{ $store.state.brandInfo.displayName }} is now “downloading”and “parsing”.<br />*Note:
									This download could take a few seconds up to 1 minute depending on your connection speed.<br />After the download is completed, your M3U
									playlist will populate onto the screen and you may begin searching all your favorite content.<br />When you have found your selected content
									click on the link and press “play” and enjoy&nbsp;
								</p>
								<p>
									<strong><br />IPTV Setting</strong><br /><br />
								</p>
								<p><br /></p>
								<p>
									<strong>Channels list and recent VOD</strong><br /><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong>/</strong>m3u8/livetv</a
									>
								</p>
								<p>
									<br /><strong>Movies&nbsp;</strong><br /><a
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										rel="noreferrer noopener"
										target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/movies</a
									><br /><br /><strong>TV Shows</strong><br /><a
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"
										rel="noreferrer noopener"
										target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/tvshows</a
									>
								</p>
								<p><br /></p>
								<p><br /></p>
								<p><br /></p>
								<p><br /></p>
								<p><br /></p>
								<p><strong>TV Guide/EPG URL</strong><br />Select Lite or Full url.&nbsp;</p>
								<p><br /></p>
								<p>Regular version – 7 day listing&nbsp;</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`" rel="noreferrer noopener" target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/full.xml.gz</a
									>
								</p>
								<p><br /></p>
								<p>Lite version - 48h listing (For old/small devices)</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`" rel="noreferrer noopener" target="_top"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/lite.xml.gz</a
									>
								</p>
								<p>&nbsp;</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'howDoIInstallOnIPad',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
